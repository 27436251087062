<template>
    <div>
        <q-card class="my-card fixed-center" style="width: 500px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6 text-weight-light">Field Services App</div>
                <q-separator spaced />
                <q-form
                    @submit="register()"
                    class="q-gutter-md q-mt-sm"
                >
                <div class="q-ma-sm row">
                    <div class="q-ma-sm col-12">
                        <q-input
                            v-model="user.firstname"
                            :dense="true"
                            label="First Name"
                            outlined
                        />
                    </div>
                    <div class="q-ma-sm col-12">
                        <q-input
                            v-model="user.lastname"
                            :dense="true"
                            label="Last Name"
                            outlined
                        />
                    </div>
                    <div class="q-ma-sm col-12">
                        <q-input
                            v-model="user.phoneNumber"
                            :dense="true"
                            label="Phone Number"
                            outlined
                        />
                    </div>
                    <div class="q-ma-sm col-12">
                        <q-input
                            v-model="user.email"
                            :dense="true"
                            label="Email"
                            type="email"
                            outlined
                        />
                    </div>
                    <div class="q-ma-sm col-12">
                        <q-input
                            v-model="user.password"
                            :dense="true"
                            label="Password"
                            type="password"
                            outlined
                        />
                    </div>
                    <div class="q-ma-sm col-12">
                        <q-input
                            v-model="user.confirmPassword"
                            :dense="true"
                            label="Confirm Password"
                            type="password"
                            outlined
                        />
                    </div>
                    <div class="q-ma-sm col-12">
                        <q-input
                            v-model="user.positionTitle"
                            :dense="true"
                            label="Position Title"
                            outlined
                        />
                    </div>
                    <div class="q-ma-sm col-12">
                        <q-input
                            v-model="user.officeLocation"
                            :dense="true"
                            label="Office Location"
                            outlined
                        />
                    </div>
                </div>
                    <div>
                        <q-btn label="Register" type="submit" color="dark"/>
                        <q-btn label="Login" class="q-ml-sm" type="submit" color="dark" to="/login"/>
                    </div>
                </q-form>
            </q-card-section>
        </q-card>
    </div>
</template>

<script>
import axios from '../../axios'
import router from '../../router/index'

export default {
    data() {
        return {
            layout: false,
            user: {},
        }
    },
    methods: {
        async register() {
            axios.post('/api/Auth/Register', this.user)
                .then(response => {
                    console.log(response)
                    router.push('/login')
                })
                .catch(error => {
                    alert(error.data.message)
                })
        },
    }
}
</script>